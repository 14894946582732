// CEGER

import { sanitizeAndParseHTML } from '../../utils'

export const EMAIL = 'certificacao@ceger.gov.pt'

export const INSTITUICAO = 'Ceger'

export const LOGIN = {
    HEADER_MESSAGE: 'Infraestrutura de Chaves Públicas',
    BODY_MESSAGE: sanitizeAndParseHTML('Bem vindo ao Portal Cliente da Entidade<br> Certificadora Comum do Estado'),
    FOOTER_MESSAGE: '© Ceger 2025',
}

export const UPLOAD_INFO_MESSAGE = sanitizeAndParseHTML(
    'Com vista a concluir o procedimento de solicitação de acesso, deverá anexar o tipo de declaração que se aplica ao serviço a solicitar, para tal consulte <a href="https://www.ecce.gov.pt/portal-cliente/acesso/" target="_blank" rel="noopener noreferrer">aqui</a> a informação disponível para o efeito.',
    { ADD_ATTR: ['target'] }
)

export const CONFIRMATION_KE_INFO_MESSAGE = sanitizeAndParseHTML(
    'É possível alterar este procedimento para receber o <strong>código de acesso</strong> por mensagem no <strong>telemóvel</strong>. Para isso, após autenticar-se no Portal Cliente, aceda ao seu <strong>Perfil</strong> no menu lateral, então registe e valide um número de telemóvel.'
)

export const CONTACT_INFO = {
    ADDRESS: 'Campus APP - Av. João XXI, n. 63',
    POSTAL_CODE: '1000-300 Lisboa',
    EMAIL: 'certificacao@ecce.gov.pt',
    LABEL: 'Entidade Certificadora Comum do Estado:',
    PHONE: '(+351) 213 923 460',
}

export const PROFILE_NOTIFICATIONS_OPTIONS = {
    VIA_EMAIL: 'Desejo receber notificações por e-email',
    VIA_SMS: 'Desejo receber notificações por SMS',
    NEWSLETTER: 'Desejo receber Newsletter sobre novidades do CEGER',
}

export const NEW_ORDER_PSEUDONYM_TOOLTIP = sanitizeAndParseHTML(
    'Acesse <a href="http://www.ceger.gov.pt" target="_blank" rel="noopener noreferrer">http://www.ceger.gov.pt</a> para esclarecer as condições e justificativas para poder aplicar pseudónimos em certificados'
)

export const NEW_ORDER_PINPUK_TOOLTIP = sanitizeAndParseHTML(
    'O titular poderá escolher posteriormente receber por Correio, SMS ou levantar no CEGER'
)

export const NEW_ORDER_CALLOUT = sanitizeAndParseHTML(
    'Caro utilizador não existe registo de compromisso com saldo para fazer pedido no âmbito do protocolo, por favor contacte a área de Certificação do Ceger.'
)

export const SMARTCARD_ACTIVATION = sanitizeAndParseHTML(
    'Se os dados estiverem corretos marque a opção abaixo e clique no botão Guardar. Caso os dados não estejam corretos entre em contato com o CEGER no e-mail <a href="mailto:certificacao@ceger.gov.pt">certificacao@ceger.gov.pt</a> ou telefone 213923460.'
)

export const MSG_CONFIRM_PAGE_EXIT =
    'Esta ação removerá todos os dados preenchidos e modificados até o momento. Tem a certeza que deseja sair da página?'
