/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Fade, Grid, Typography } from '@material-ui/core'

import Link from '@material-ui/core/Link'
import logoCompete from '../../assets/images/commons/logo-compete2020.svg'
import logoPortugal from '../../assets/images/commons/logo-portugal2020.svg'
import logoFUDR from '../../assets/images/commons/logo-FUDR.svg'
import logoECCE from '../../assets/images/commons/logo-ecce.svg'
import authGov from '../../assets/images/commons/authgov.svg'
import logoFixed from '../../assets/images/logoFixed.svg'
import logo from '../../assets/images/logo.svg'

import LoginForm from '../../forms/Login/Login.form'

// import Callout from '../../components/templates/Callout/Callout'

import {
    Paper,
    Title,
    AccessRecovery,
    Register,
    Divider,
    DividerText,
    SButton,
    SLoginForm,
    LoginFooter,
    LoginHeader,
    LogoList,
    FooterBlank,
} from './Login.styles'

import { EMAIL, INSTITUICAO } from '../../constants/dictionary/dictionary'

function LoginPage() {
    const history = useHistory()

    function handleLoginAuthGovPt() {
        window.location.href = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_CONTEXT}/users/saml/requestSamlToken`
    }

    function handleRecoveryPassword() {
        history.push('/recovery')
    }

    function handleRequestUser() {
        history.push('/request')
    }

    return (
        <>
            <LoginHeader>
                <img className='logo-ecce' src={logoECCE} alt='Logo ECCE - Entidade Certificadora Comum do Estado' />
                <img className='logo-instituicao-2' src={logo} alt='Logo' />
                <img className='logo-instituicao' src={logoFixed} alt='Logo' />
            </LoginHeader>

            <Fade in>
                <Paper elevation={2}>
                    {/* <Callout>
                        Informa-se que no próximo dia 16 de janeiro, devido a uma intervenção técnica, o portal cliente
                        estará inacessível, a partir das 20:00h.
                        <br />
                        Prevê-se que a reposição da normalidade do serviço suceda num prazo máximo, estimado, de 2h.
                        <br />
                        Lamentamos quaisquer constrangimentos causados.
                        <br />
                        Qualquer dúvida ou apoio que necessite, entre em contato connosco para o service desk para o nr
                        213 923 400.
                    </Callout> */}
                    <Title>Acesso ao Portal Cliente</Title>
                    <Register>
                        <Typography component='h3'>Não estou registado</Typography>
                        <Button variant='outlined' color='primary' onClick={handleRequestUser}>
                            Solicite aqui o seu 1° acesso
                        </Button>
                    </Register>
                    <Divider>
                        <DividerText>OU</DividerText>
                    </Divider>
                    <SLoginForm>
                        <Typography component='h3' gutterBottom>
                            Já estou registado
                        </Typography>
                        <LoginForm />
                    </SLoginForm>
                    <Divider>
                        <DividerText>OU</DividerText>
                    </Divider>
                    <SButton onClick={handleLoginAuthGovPt}>
                        <img src={authGov} alt='' />
                        <span>autenticação.gov</span>
                    </SButton>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <AccessRecovery onClick={handleRecoveryPassword}>Redefinir Palavra-Passe</AccessRecovery>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ marginTop: 30 }}>
                        <Grid item xs={12}>
                            <Typography component='h3' gutterBottom>
                                Em caso de dúvidas para efetuar o registo no Portal Cliente{' '}
                                <a href={`mailto:${EMAIL}`}>clique aqui</a> para enviar a sua mensagem.
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Fade>

            {INSTITUICAO === 'Ceger' ? (
                <LoginFooter>
                    <Typography variant='caption' display='block'>
                        Cofinanciado por:
                    </Typography>
                    <LogoList role='list'>
                        <Link
                            href='https://www.compete2020.gov.pt/'
                            title='Compete 2020'
                            rel='noopener'
                            target='_blank'
                        >
                            <img className='footer-logo' src={logoCompete} alt='Compete 2020' />
                        </Link>
                        <Link href='https://www.portugal2020.pt/' title='Portugal 2020' rel='noopener' target='_blank'>
                            <img className='footer-logo' src={logoPortugal} alt='Portugal 2020' />
                        </Link>
                        <Link
                            href='https://ec.europa.eu/regional_policy/pt/funding/erdf/'
                            title='UE - Fundo Europeu de Desenvolvimento Regional'
                            rel='noopener'
                            target='_blank'
                        >
                            <img className='footer-logo' src={logoFUDR} alt='União Européia' />
                        </Link>
                    </LogoList>
                </LoginFooter>
            ) : (
                <FooterBlank />
            )}
        </>
    )
}

export default LoginPage
